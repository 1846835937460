import { active_key }             from "./vars.js";
import { toggleActive, findElem } from "./helpers.js";

function accountFormAddActive() {
    const formBtn = findElem('.account__form-edit-btn');

    if (formBtn) {
        formBtn.addEventListener('click', () => toggleActive([formBtn.parentElement.parentElement]));
    }
}

function showAccountPopup() {
    const showPopupBtn = findElem('.header__login-content--pc');

    if (showPopupBtn) {
        const popupWindow = showPopupBtn.nextElementSibling,
            closePopupWindow = findElem('.account__modal-close-btn', popupWindow);

        [showPopupBtn, closePopupWindow].forEach(btn => btn.addEventListener('click', () => popupWindow.classList.toggle(active_key)));
    }
}

export { accountFormAddActive, showAccountPopup };