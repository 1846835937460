import { findElem } from "./helpers.js";

function showPopup(content, header = '', footer = '') {
    const html = [
        '<div id="magnific-popup">',
        '<div class="magnific-popup-content flex-14">',
        `<div class="magnific-popup-header mb-3">${header}</div>`,
        `<div class="magnific-popup-body mb-3">${content}</div>`,
        `<div class="magnific-popup-footer">${footer}</div>`,
        '</div></div>'
    ].join('');

    $.magnificPopup.open({
        items: {
            src: html,
            type: 'inline',
        },
        fixedContentPos: true,
    });
}

function productImagesPopup() {
    const processDownloadLink = imgInfo => {
        if (!Object.keys(imgInfo).length) {
            return;
        }

        const src = imgInfo.imgSrc.replace(/(cache\/\w+\/)/, '').replace(/(?<=PL-\d+_\d+)-\d+\w\d+\.\w+((?=\?v=.*))?/, '.jpg');

        findElem('.download-prod-img', imgInfo.parentElem)?.remove();

        imgInfo.parentElem.insertAdjacentHTML('beforeend',
            `<a class="download-prod-img" href="${src}" download="${window.productSku ?? ''}"><i class="fa-solid fa-cloud-arrow-down"></i></a>`
        );
    }

    $('.magnific-images').magnificPopup({
        tClose: 'Закрити (Esc)', // Alt text on close button
        tLoading: 'Завантаження...', // Text that is displayed during loading. Can contain %curr% and %total% keys
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            preload: [0, 1],
            tCounter: '<span class="mfp-counter">%curr% з %total%</span>',
            tPrev: 'Попереднє зображення', // title for left button
            tNext: 'Наступне зображення', // title for right button
        },
        fixedContentPos: true,
        mainClass: 'mfp-with-zoom', // this class is for CSS animation below
        zoom: {
            enabled: true, // By default it's false, so don't forget to enable it
            duration: 400, // duration of the effect, in milliseconds
            easing: 'ease', // CSS transition easing function
        },
        callbacks: {
            markupParse: function (template, values, item) {
                const figureElem = findElem('figure', template[0]);

                if (figureElem) {
                    processDownloadLink({
                        parentElem: figureElem,
                        imgSrc: item.src,
                    });
                }
            },
        }
    });
}

export { showPopup, productImagesPopup };