function slider() {
    $('.main-slider__slider').slick({
        infinite: true,
        dots: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        lazyLoad: 'ondemand',
        prevArrow: '<button type="button" class="slick-prev">' +
            '<img src="image/catalog/icons/main-slider/arrow-left.svg" alt="">' +
            '</button>',
        nextArrow: '<button type="button" class="slick-next">' +
            '<img src="image/catalog/icons/main-slider/arrow-right.svg" alt="">' +
            '</button>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            },
        ]
    });
}

function productImagesSlider() {
    $('.product__main-imgs-container').slick({
        arrows: false,
        asNavFor: '.product__thumbnail-imgs',
        centerMode: true,
        variableWidth: true,
        mobileFirst: true,
        dots: true,
        responsive: [{
            breakpoint: 570,
            settings: {
                dots: false
            }
        }]
    });

    $('.product__thumbnail-imgs').slick({
        slidesToShow: 3,
        asNavFor: '.product__main-imgs-container',
        centerMode: true,
        focusOnSelect: true,
        arrows: false,
        mobileFirst: true,
        responsive: [{
            breakpoint: 570,
            settings: {
                vertical: true,
                verticalSwiping: true,
            }
        }]
    });
}

export { slider, productImagesSlider };