import { arrayFrom, toggleActive, findElem, findElems } from "./helpers.js";

function languageMenu() {
    const openMenuBtn = findElems('.header__lang-current');

    arrayFrom(openMenuBtn).forEach(btn => {
        const langList = findElem('.header__lang-list', btn.parentElement);

        if (window.deviceType === 'desktop') {
            btn.parentElement.addEventListener('mouseenter', () => toggleActive([btn, langList]));
            btn.parentElement.addEventListener('mouseleave', () => toggleActive([btn, langList]));
        }

        btn.addEventListener('click', () => toggleActive([btn, langList]));
    });
}

export { languageMenu };