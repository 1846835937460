import {
    active_key, d_none_key, document_var, error_key,
    html_key, index_php_key, screenWidth, spinner_border_key
}                    from "./vars.js";
import {
    addSelector, fetchFunc, redirect, removeElement,
    removeSelector, takeSpinnerHtml, findElem,
    debounce
}                    from "./helpers.js";
import { showToast } from "./toast.js";

function search() {
    const form = findElem('.header__search-form');

    if (!form) {
        return;
    }

    const searchImg = findElem('.header__search-img'),
        searchField = findElem('.header__search-field'),
        searchSubmitBtn = findElem('.header__search-btn'),
        searchBlock = findElem('.header__search-input-block'),
        errorBlock = findElem('.header__search-alert-error');

    function tryFindProducts(parentBlock, keywords) {
        removeElement('.header__search-alert-result');
        removeElement('.header__search-alert-result-empty');

        parentBlock.insertAdjacentHTML('afterbegin', takeSpinnerHtml('text-success'));
        searchField.readOnly = true;
        searchSubmitBtn.disabled = true;

        fetchFunc(index_php_key + 'common/search.liveSearch&search=' + encodeURIComponent(keywords), {})
            .then(json => {
                if (json[html_key]) {
                    form.closest('.header__search-inner').insertAdjacentHTML('beforeend', json[html_key]);

                    processResults(false);
                } else if (json[error_key]) {
                    showToast('danger', json[error_key]);
                }
            })
            .finally(() => {
                removeElement('.' + spinner_border_key, parentBlock);
                searchField.readOnly = false;
                searchSubmitBtn.disabled = false;
            });
    }

    const debounceTryFindProducts = debounce(tryFindProducts, 1000);

    function processResults(isNeedRemoveSelector) {
        const result = findElem('.header__search-alert-result'),
            emptyResult = findElem('.header__search-alert-result-empty');

        let selectors = [];

        screenWidth > 829 ? selectors.push(active_key, 'pc') : selectors.push(active_key);

        if (result) {
            if (isNeedRemoveSelector) {
                if (screenWidth <= 829) {
                    removeSelector(result, selectors);
                }
            } else {
                addSelector(result, selectors);
            }

            // isNeedRemoveSelector && screenWidth <= 829 ? removeSelector(result, selectors) : addSelector(result, selectors);
        }

        if (emptyResult) {
            if (isNeedRemoveSelector) {
                if (screenWidth <= 829) {
                    removeSelector(emptyResult, selectors);
                }
            } else {
                addSelector(emptyResult, selectors);
            }

            // isNeedRemoveSelector && screenWidth <= 829 ? removeSelector(emptyResult, selectors) : addSelector(emptyResult, selectors);
        }
    }

    function validateSearchValue(searchVal) {
        const isSearchValValid = searchVal.length >= 3;

        removeSelector(searchSubmitBtn, d_none_key);
        removeSelector(searchBlock, error_key);
        removeSelector(errorBlock, active_key);
        removeSelector(searchImg, error_key);

        addSelector(searchImg, isSearchValValid ? active_key : error_key);

        processResults(!isSearchValValid);

        if (isSearchValValid) {
            debounceTryFindProducts(form.closest('.header__search-input-block'), searchVal);
        } else {
            addSelector(searchSubmitBtn, d_none_key);
            addSelector(searchBlock, error_key);
            addSelector(errorBlock, active_key);
        }

        if (!isSearchValValid) {
            addSelector(searchSubmitBtn, d_none_key);
            addSelector(searchBlock, error_key);
            addSelector(errorBlock, active_key);
        }
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        if (this.checkValidity()) {
            redirect(this.action + encodeURIComponent(searchField.value));
        }
    }

    function handleInput() {
        validateSearchValue(searchField.value.trim());
    }

    function toggleResults(searchResult, searchEmptyResult, isShow) {
        if (searchResult) {
            isShow ? addSelector(searchResult, active_key) : removeSelector(searchResult, active_key);
        }

        if (searchEmptyResult) {
            isShow ? addSelector(searchEmptyResult, active_key) : removeSelector(searchEmptyResult, active_key);
        }
    }

    function handleClick(e) {
        const searchResult = findElem('.header__search-alert-result'),
            searchEmptyResult = findElem('.header__search-alert-result-empty');

        if (e.target === searchField) {
            if (!searchField.readOnly) {
                addSelector(searchBlock, active_key);

                toggleResults(searchResult, searchEmptyResult, true);
            }
        } else {
            removeSelector(searchBlock, [active_key, error_key]);
            removeSelector(errorBlock, active_key);
            removeSelector(searchImg, [active_key, error_key]);

            if (!e.target.closest('.header__search-inner')) {
                toggleResults(searchResult, searchEmptyResult, false);
            }
        }
    }

    document_var.addEventListener('click', handleClick);
    searchField.addEventListener('input', handleInput);
    form.addEventListener('submit', handleFormSubmit);
}

export { search };