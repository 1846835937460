import { addSelector, removeSelector, findElem } from "./helpers.js";
import { d_none_key }                            from "./vars.js";

function moveSomeContent(selectorWhereMove, selectorForMove) {
    const contentWhereMove = findElem(selectorWhereMove),
        contentForMove = findElem(selectorForMove);

    if (contentWhereMove && contentForMove) {
        contentWhereMove.append(contentForMove);

        removeSelector(contentWhereMove, d_none_key);
        addSelector(contentForMove, 'd-xl-flex');
    }
}

export { moveSomeContent };