import {
    addSelector, arrayFrom, removeElement,
    removeSelector, takeSpinnerHtml, findElems
}                                                          from "./helpers.js";
import { document_var, lazy_load_key, spinner_border_key } from "./vars.js";

function lazyLoadImgs(parentBlock = document_var) {
    const loadImage = img => {
        const parentBlock = img.parentElement;

        parentBlock.insertAdjacentHTML('afterbegin', takeSpinnerHtml('text-success'));
        addSelector(parentBlock, lazy_load_key);

        img.srcset = img.dataset.srcset;

        img.addEventListener('load', () => {
            img.removeAttribute('data-srcset');

            removeSelector(parentBlock, lazy_load_key);
            removeElement('.' + spinner_border_key, parentBlock);
        });
    };

    const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadImage(entry.target);
                obs.unobserve(entry.target);
            }
        });
    });

    arrayFrom(findElems('[data-srcset]', parentBlock)).forEach(img => observer.observe(img));
}

function loadDynamicIframe() {
    const loadIframe = input => {
        const parentBlock = input.parentElement,
            iframe = document_var.createElement('iframe');

        parentBlock.insertAdjacentHTML('afterbegin', takeSpinnerHtml('text-success'));
        addSelector(parentBlock, lazy_load_key);

        iframe.src = input.dataset.ajaxIframe;
        iframe.width = 560;
        iframe.height = 314;
        iframe.allowFullscreen = true;

        parentBlock.insertAdjacentElement('beforeend', iframe);

        iframe.addEventListener('load', () => {
            removeSelector(parentBlock, lazy_load_key);
            removeElement('.' + spinner_border_key, parentBlock);
        });

        input.remove();
    }

    const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadIframe(entry.target);
                obs.unobserve(entry.target);
            }
        });
    });

    arrayFrom(findElems('[data-ajax-iframe]')).forEach(input => observer.observe(input));
}

export { lazyLoadImgs, loadDynamicIframe };