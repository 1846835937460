import { backLink, scrollToAnchor, sortLink }     from "./utils/helpers.js";
import {
    loadMoreReviews, loadReviewAnswers,
    sendAnswerForReview, sendProductReview
}                                                 from "./utils/productReview.js";
import { showSomeContent }                        from "./utils/showSomeContent.js";
import { productImagesSlider, slider }            from "./utils/slider.js";
import { showPageDescr }                          from "./utils/showPageDescr.js";
import { search }                                 from "./utils/search.js";
import { languageMenu }                           from "./utils/languageMenu.js";
import { lazyLoadImgs, loadDynamicIframe }        from "./utils/lazyLoad.js";
import { toggleHeader }                           from "./utils/toggleHeader.js";
import { productImagesPopup }                     from "./utils/popup.js";
import { validateForm }                           from "./utils/validate.js";
import { setRating }                              from "./utils/rating.js";
import { parsePhoneNumber }                       from "./utils/parsePhone.js";
import {
    addToCart, listenQuantityFieldInProductCard,
    quantityOperation, showCartModal
}                                                 from "./utils/cart.js";
import { redesignSelect }                         from "./utils/select2.js";
import { accountFormAddActive, showAccountPopup } from "./utils/account.js";
import { setTransformForPCMenuItem }              from "./utils/pcMenu.js";
import { wishlist }                               from "./utils/wishlist.js";
import { loadMoreContent }                        from "./utils/loadMoreContent.js";
import { moveSomeContent }                        from "./utils/moveSomeContent.js";
// import { login }                                  from "./utils/login.js";
import { runOtherFuncs }                          from "./utils/runOtherFuncs.js";
import { dynamicLoadContent }                     from "./utils/dynamicLoadContent.js";
import { index_php_key }                          from "./utils/vars.js";
import { setDeviceType }                          from "./utils/deviceType.js";

/*document_var.addEventListener('click', e => {
        console.log(e);
    });*/

// observeAddedOtherScripts();

const runFuncs = () => {
    showSomeContent();

    setDeviceType();
    slider();
    showPageDescr();
    search();
    languageMenu();
    lazyLoadImgs();
    backLink();
    toggleHeader();
    productImagesSlider();

    productImagesPopup();
    validateForm();
    setRating();
    // setCustomScrollForBreadcrumbs();
    parsePhoneNumber();
    quantityOperation();
    redesignSelect();
    accountFormAddActive();
    setTransformForPCMenuItem();
    /*youtubeModal();*/
    addToCart();
    showCartModal();
    // setCustomScrollForOther('.custom-scroll');
    wishlist('.prods__wishlist');
    loadMoreContent('.category__products');
    loadMoreContent('.wishlist__products');
    loadMoreContent('.account__item--last-orders', false, '#last-orders');
    loadMoreContent('.blog-list__articles');
    sortLink();
    moveSomeContent('.category__top-filter-chosen', '.category__top-filter-chosen-inner');
    scrollToAnchor('.product__top-link');
    sendProductReview();
    loadMoreReviews();
    sendAnswerForReview();
    loadReviewAnswers();
    // login('.login__form');
    runOtherFuncs();
    showAccountPopup();
    loadDynamicIframe();
    dynamicLoadContent('data-ajax-load-products', index_php_key + 'extension/some_products/module/products.loadProducts');
    dynamicLoadContent('data-ajax-load-content', index_php_key + 'common/footer.loadContent');
    dynamicLoadContent(
        'data-ajax-load-cat-prods',
        index_php_key + 'product/category.loadMoreProducts',
        '.category__products-list .prods__list'
    );
};

runFuncs();
listenQuantityFieldInProductCard();