import { editCart, quantityOperation, removeProduct } from "./cart.js";
import { validateForm }                               from "./validate.js";
import { parsePhoneNumber }                           from "./parsePhone.js";
import { byeOneClickCTP }                             from "./byeOneClick.js";

function runCartModalWindowFuncs() {
    editCart();
    removeProduct();
    quantityOperation();
    validateForm();
    parsePhoneNumber();
    byeOneClickCTP();
}

export { runCartModalWindowFuncs };