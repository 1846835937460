import {
    addSelector, arrayFrom, fetchFunc, isBuyOneClick,
    newFormData, setCartShowTotals, setLocalStorageVal,
    findElem, findElems, debounce
}                                  from "./helpers.js";
import {
    ajaxUrlToCart, body_var, html_key, is_oct_buy_one_click,
    product_id_key, quantity_key, success_key, total_products_key
}                                  from "./vars.js";
import { runCartModalWindowFuncs } from "./runCartModalWindowFuncs.js";
import { showPopup }               from "./popup.js";

function takeMultipleProductVal(neededDomElem) {
    const multipleQuantity = neededDomElem.dataset.multipleProduct;

    return (neededDomElem && multipleQuantity !== undefined ? parseInt(multipleQuantity) : 1);
}

function updateCartQuantity(input) {
    const url = ajaxUrlToCart + '.edit',
        form = newFormData();

    form.set('key', input.dataset.productKey);
    form.set(quantity_key, input.value);
    form.set(is_oct_buy_one_click, isBuyOneClick());

    fetchFunc(url, form)
        .then(json => {
            if (json[total_products_key]) {
                setCartShowTotals(json[total_products_key]);
            }

            if (json[html_key]) {
                showPopup(json[html_key], (json[success_key] ?? ''));
            }
        })
        .then(runCartModalWindowFuncs);
}

function correctQuantity(quantity, multipleProductVal) {
    if (quantity < multipleProductVal) {
        quantity = multipleProductVal;
    } else if (quantity % multipleProductVal !== 0) {
        quantity += (multipleProductVal - (quantity % multipleProductVal));
    }

    return quantity;
}

function processQuantityInInput(updateCartQuantityDebounce) {
    const multipleProductVal = takeMultipleProductVal(this),
        currentValue = parseInt(this.value.replace(/\D+/gu, ''));

    this.value = correctQuantity(currentValue, multipleProductVal);

    if (typeof updateCartQuantityDebounce === 'function') {
        updateCartQuantityDebounce(this);
    }
}

function listenQuantityFieldInProductCard() {
    const inputField = findElem('[data-product-quantity]');

    if (!inputField) {
        return;
    }

    const processQuantityDebounce = debounce(processQuantityInInput, 800);

    inputField.addEventListener('input', function () {
        processQuantityDebounce.call(this, null)
    });
}

function editCart() {
    const quantityFields = findElems('[data-product-key]'),
        updateCartQuantityDebounce = debounce(updateCartQuantity, 800),
        processQuantityDebounce = debounce(processQuantityInInput, 800);

    arrayFrom(quantityFields).forEach(input => {
        input.oninput = function () {
            processQuantityDebounce.call(this, updateCartQuantityDebounce);
        };
    });
}

function quantityOperation() {
    const updateDebounce = debounce(updateCartQuantity, 800),
        plusBtns = findElems('[data-quant-plus]'),
        minusBtns = findElems('[data-quant-minus]'),
        sum = (isPlus, inputField, btn) => {
            const multipleProductVal = takeMultipleProductVal(inputField),
                newQuantity = parseInt(inputField.value.replace(/\D+/gu, '')) + (isPlus ? multipleProductVal : -multipleProductVal);

            inputField.value = correctQuantity(newQuantity, multipleProductVal)

            if (btn.dataset.notUpdateCart === undefined) {
                updateDebounce(inputField);
            }
        };

    arrayFrom(plusBtns).forEach(btn => btn.onclick = () => sum(true, btn.previousElementSibling, btn));
    arrayFrom(minusBtns).forEach(btn => btn.onclick = () => sum(false, btn.nextElementSibling, btn));
}

function removeProduct() {
    const removeProductBtns = findElems('[data-remove-prod-cart]'),
        url = ajaxUrlToCart + '.remove',
        form = newFormData();

    arrayFrom(removeProductBtns).forEach(btn => {
        btn.onclick = function () {
            form.set(is_oct_buy_one_click, isBuyOneClick());
            form.set('key', this.dataset.removeProdCart);

            fetchFunc(url, form)
                .then(json => {
                    if (typeof json[total_products_key] !== undefined) {
                        setCartShowTotals(json[total_products_key]);
                    }

                    showPopup(json[html_key], (json[success_key] ?? ''))
                })
                .then(runCartModalWindowFuncs);
        };
    });
}

function showCartModal() {
    const showCartBtns = findElems('.header__cart-content'),
        url = ajaxUrlToCart + '.showModal',
        form = newFormData();

    arrayFrom(showCartBtns).forEach(btn => {
        btn.onclick = () => {
            form.set(is_oct_buy_one_click, isBuyOneClick());

            fetchFunc(url, form)
                .then(json => showPopup(json[html_key]))
                .then(runCartModalWindowFuncs);
        };
    });
}

function addToCartEffect(product) {
    if (!product) {
        return;
    }

    const cartMobile = findElem('.header__cart--mobile > button'),
        cartPC = findElem('.header__cart--pc > button'),
        productImg = findElem('.product__main-imgs-img--main, .prods__item-img > img', product);

    if (!productImg) {
        return;
    }

    const productFly = productImg.cloneNode(),
        productFlyWidth = productImg.offsetWidth,
        productFlyHeight = productImg.offsetHeight,
        productFlyTop = productImg.getBoundingClientRect().top,
        productFlyLeft = productImg.getBoundingClientRect().left;

    let cartFlyTop = 0,
        cartFlyLeft = 0;

    if (getComputedStyle(cartMobile.parentElement).display !== 'none') {
        cartFlyTop = cartMobile.getBoundingClientRect().top;
        cartFlyLeft = cartMobile.getBoundingClientRect().left;
    } else {
        cartFlyTop = cartPC.getBoundingClientRect().top;
        cartFlyLeft = cartPC.getBoundingClientRect().left;
    }

    addSelector(productFly, 'fly-img');

    productFly.style.cssText = [
        `top:${productFlyTop}px;`,
        `left:${productFlyLeft}px;`,
        `width:${productFlyWidth}px;`,
        `height:${productFlyHeight}px`,
    ].join('');

    body_var.append(productFly);

    productFly.addEventListener('transitionend', () => productFly.remove());

    setTimeout(() => {
        productFly.style.cssText = [
            `top:${cartFlyTop}px;`,
            `left:${cartFlyLeft + 25}px;`,
            `width:0;`,
            `height:0`,
        ].join('');
    }, 10);
}

function tryTakeOptions(options, form) {
    if (this.closest('.product__buy')) {
        arrayFrom(options).forEach(option => {
            if (option.checked) {
                form.set(`option[${option.dataset.optionCheck}][]`, option.value);
            }
        });
    }
}

function addToCart() {
    const addToCartBtns = findElems('[data-cart-add]'),
        addToCartOneClick = findElems('[data-add-one-click]'),
        options = findElems('[data-option-check]'),
        url = ajaxUrlToCart + '.add',
        form = newFormData();

    function facebookAction(json) {
        if (json['facebook'] && json['facebook']['AddToCart'] && typeof fbq === 'function') {
            fbq('track', 'AddToCart', json['facebook']['AddToCart']);
        } else if (typeof fbq !== 'function') {
            console.error('fbq() is not a function!');
        }
    }

    arrayFrom(addToCartBtns).forEach(btn => {
        btn.onclick = function () {
            addToCartEffect(this.closest('.prods__item'));
            addToCartEffect(findElem('.product__main-imgs-container'));

            setLocalStorageVal(is_oct_buy_one_click, 0);

            tryTakeOptions.call(this, options, form);

            form.set(product_id_key, this.dataset.cartAdd);

            let quantity,
                productContentElem = this.closest('.product__content');

            if (productContentElem) {
                quantity = findElem('.product__quantity-input', productContentElem).value;
            } else {
                quantity = takeMultipleProductVal(this);
            }

            form.set(quantity_key, quantity);

            fetchFunc(url, form)
                .then(json => {
                    if (json[total_products_key]) {
                        setCartShowTotals(json[total_products_key]);
                    }

                    facebookAction(json);
                });
        };
    });

    arrayFrom(addToCartOneClick).forEach(btn => {
        btn.onclick = function () {
            addToCartEffect(this.closest('.prods__item'));

            setLocalStorageVal(is_oct_buy_one_click, 1);

            tryTakeOptions.call(this, options, form);

            form.set(product_id_key, this.dataset.addOneClick);
            form.set(quantity_key, 1);
            form.set(is_oct_buy_one_click, 1);

            fetchFunc(url, form)
                .then(json => {
                    setTimeout(() => {
                        if (json[html_key]) {
                            showPopup(json[html_key], (json[success_key] ?? ''));

                            runCartModalWindowFuncs();
                        }
                    }, 200);

                    if (json[total_products_key]) {
                        setCartShowTotals(json[total_products_key]);
                    }

                    facebookAction(json);
                });
        };
    });
}

export {
    quantityOperation, editCart,
    listenQuantityFieldInProductCard, addToCart,
    showCartModal, removeProduct
};