import { toggleActive, findElem } from "./helpers.js";

function showPageDescr() {
    const showBtn = findElem('.page-description__show-btn'),
        hideBtn = findElem('.page-description__hide-btn'),
        description = findElem('.page-description__inner');

    if (!showBtn || !hideBtn || !description) {
        return;
    }

    [showBtn, hideBtn].forEach(btn =>
        btn.addEventListener('click', () => toggleActive([description, showBtn, hideBtn]))
    );
}

export { showPageDescr };