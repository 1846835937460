import { arrayFrom, findElem, findElems } from "./helpers.js";

function setTransformForPCMenuItem() {
    const menuBlock = findElem('.header-pc-menu__content');

    if (menuBlock) {
        const subMenuItems = findElems('.pc-main-sub-menu', menuBlock),
            positionMenuBlock = menuBlock.getBoundingClientRect();

        arrayFrom(subMenuItems).forEach(menuItem => {
            const menuItemPosition = menuItem.getBoundingClientRect();
            let distanceToMenuBlock = 0;

            if (!menuItem.closest('.pc-main-sub-menu--first-item')) {
                distanceToMenuBlock = -(Number(Math.abs(positionMenuBlock.top - menuItemPosition.top)) + 17);
            }

            menuItem.style.cssText = `--distance-to-top: ${distanceToMenuBlock}px`;
        });
    }
}

export { setTransformForPCMenuItem };