import {
    addSelector, arrayFrom, removeSelector,
    findElems
} from "./helpers.js";
import { active_key }                             from "./vars.js";

function setRating() {
    const ratingBlock = findElems('.rating');

    arrayFrom(ratingBlock).forEach(block => {
        const radios = findElems('.option-check-input', block),
            arrayRadios = arrayFrom(radios),
            checkRating = () => {
                arrayRadios.forEach(radio => removeSelector(radio.nextElementSibling, active_key));

                for (let i = 0; i < arrayRadios.length; i++) {
                    addSelector(arrayRadios[i].nextElementSibling, active_key);

                    if (arrayRadios[i].checked) {
                        break;
                    }
                }
            };

        arrayRadios.forEach(radio => radio.addEventListener('click', checkRating));
    });
}

export { setRating };