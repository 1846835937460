import {
    addSelector, arrayFrom, fetchFunc, findElem, findElems,
    newFormData, removeElement, removeSelector
}                    from "./helpers.js";
import {
    d_none_key, error_key, html_key, index_php_key,
    product_review_key, spinner_border_key, success_key
}                    from "./vars.js";
import { showPopup } from "./popup.js";

function processReviewResult(json, errorsBlock, form) {
    if (json[error_key] && errorsBlock) {
        removeSelector(errorsBlock, d_none_key);
        errorsBlock.innerHTML = '';

        for (const errorKey in json[error_key]) {
            errorsBlock.insertAdjacentHTML('beforeend', json[error_key][errorKey] + '<br/><br/>');
        }
    } else {
        addSelector(errorsBlock, d_none_key);
    }

    if (json[success_key]) {
        showPopup(json[success_key]);

        removeSelector(form, 'was-validated')
        form.reset();
    }
}

function sendProductReview() {
    const reviewForm = findElem('#review-form'),
        errorsBlock = findElem('.review-errors');

    if (!reviewForm) {
        return;
    }

    const url = `${index_php_key}${product_review_key}.write&review_token=${reviewForm.dataset.reviewToken}&product_id=${reviewForm.dataset.reviewProductId}`;

    reviewForm.addEventListener('submit', e => {
        if (!reviewForm.checkValidity()) {
            return;
        }

        e.preventDefault();

        fetchFunc(url, newFormData(reviewForm))
            .then(json => processReviewResult(json, errorsBlock, reviewForm));
    });
}

function sendAnswerForReview() {
    const reviewToken = findElem('[data-review-token]');

    if (!reviewToken) {
        return;
    }

    const url = `${index_php_key}${product_review_key}.write&review_token=${reviewToken.dataset.reviewToken}`;

    arrayFrom(findElems('.product__accordion-form--answer')).forEach(form => {
        form.addEventListener('submit', () => {
            if (form.checkValidity()) {
                fetchFunc(url, newFormData(form))
                    .then(json => processReviewResult(json, findElem('.answer-errors', form), form));
            }
        });
    });
}

function loadMoreReviews() {
    const nextPageLink = findElem('.product__reviews-list .pagination-next'),
        loadMoreBtn = findElem('.product__reviews-load-more'),
        form = newFormData();

    if (!nextPageLink && loadMoreBtn) {
        addSelector(loadMoreBtn, d_none_key);

        return;
    } else if (!loadMoreBtn) {
        return;
    }

    const loader = loadMoreBtn.firstElementChild;

    loadMoreBtn.onclick = () => {
        form.set('load_more', 1);

        addSelector(loadMoreBtn, d_none_key);
        removeSelector(loader, d_none_key);

        fetchFunc(nextPageLink.href, form)
            .then(json => {
                if (json[html_key]) {
                    nextPageLink.parentElement.parentElement.parentElement.remove();

                    findElem('.product__reviews-list')?.insertAdjacentHTML('beforeend', json[html_key]);
                    addSelector(loader, d_none_key);
                    loadMoreReviews();
                    sendAnswerForReview();
                    loadReviewAnswers();

                    if (!nextPageLink) {
                        removeSelector(loadMoreBtn, d_none_key);
                    }
                }
            });
    };
}

function loadReviewAnswers() {
    arrayFrom(findElems('.product__reviews-item-answer-show')).forEach(btn => {
        const url = `${index_php_key}${product_review_key}.takeReviewAnswers`,
            form = newFormData();

        btn.onclick = () => {
            const blockForResult = btn.previousElementSibling,
                reviewId = btn.dataset.reviewId;

            if (!reviewId) {
                return;
            }

            form.set('review_id', reviewId);

            fetchFunc(url, form)
                .then(json => {
                    if (json[html_key]) {
                        blockForResult.insertAdjacentHTML('afterbegin', json[html_key]);
                        removeElement('.' + spinner_border_key, blockForResult);
                        btn.removeAttribute('data-review-id');
                    }
                });
        };
    });
}

export { loadMoreReviews, sendProductReview, sendAnswerForReview, loadReviewAnswers }