import { active_key, document_var, is_oct_buy_one_click, spinner_border_key } from "./vars.js";

export const findElem = (selector, context = document_var) => context.querySelector(selector),
    findElems = (selector, context = document_var) => context.querySelectorAll(selector),
    arrayFrom = pseudoArray => Array.from(pseudoArray),
    addSelector = (element, selector) => Array.isArray(selector) ? element.classList.add(...selector) : element.classList.add(selector),
    removeSelector = (element, selector) => Array.isArray(selector) ? element.classList.remove(...selector) : element.classList.remove(selector),
    toggleClass = (element, selector) => element.classList.toggle(selector),
    toggleActive = (DOMElements = []) => arrayFrom(DOMElements).forEach(element => toggleClass(element, active_key)),
    setLocalStorageVal = (key, value) => localStorage.setItem(key, value),
    getLocalStorageVal = key => localStorage.getItem(key),
    isContainsSelector = (selector, element) => element.classList.contains(selector),
    setCartShowTotals = totalProducts => arrayFrom(findElems('.header__cart-content > span > span')).forEach(cartTotal => cartTotal.textContent = totalProducts),
    redirect = url => location = url,
    isBuyOneClick = () => (getLocalStorageVal(is_oct_buy_one_click) ?? 0),
    newFormData = (form = null) => form instanceof HTMLFormElement ? new FormData(form) : new FormData(),
    backLink = () => findElem('.breadcrumbs__link-back')?.addEventListener('click', () => history.back()),
    sortLink = () => $('.category__top-select').on('select2:select', e => redirect(e.target.value)),
    removeElement = (selector, context = document_var) => findElem(selector, context)?.remove(),
    takeSpinnerHtml = (selector = '') => `<div class="${spinner_border_key} ${selector}" role="status"></div>`;

function throttle(func, ms) {
    let isThrottled = false,
        savedArgs,
        savedThis;

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(() => {
            isThrottled = false;

            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

function scrollToTop(anchor = '') {
    const behavior = 'smooth';

    if (!anchor) {
        scrollTo({
            top: 0,
            behavior
        });
    } else {
        findElem(anchor)?.scrollIntoView({
            behavior
        })
    }
}

function debounce(func, delay) {
    let timeout;

    return function () {
        const context = this, args = arguments;

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}

async function fetchFunc(url, data, method = 'POST', contentType = 'application/json;charset=utf-8') {
    let obj = {
        method,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        }
    };

    if (method.toUpperCase() === 'GET') {
        obj = {};
    } else if (!(data instanceof FormData)) {
        obj.headers.contentType = contentType;
        obj.body = JSON.stringify(data);
    } else if (data instanceof FormData) {
        obj.body = data;
    }

    return (await fetch(url, obj)).json();
}

function scrollToAnchor(selector) {
    arrayFrom(findElems(selector)).forEach(link => {
        link.addEventListener('click', function (e) {
            e.preventDefault();

            findElem(this.href.replace(/^(.*)(?=#)/, ''))?.scrollIntoView({
                behavior: 'smooth'
            })
        })
    })
}

export { throttle, scrollToTop, debounce, fetchFunc, scrollToAnchor };