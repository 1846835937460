import {
    addSelector, fetchFunc, newFormData,
    scrollToTop, findElem
}                        from "./helpers.js";
import { d_none_key }    from "./vars.js";
import { addToCart }     from "./cart.js";
import { lazyLoadImgs }  from "./lazyLoad.js";
import { runOtherFuncs } from "./runOtherFuncs.js";

function loadMoreContent(contentBlockSelector, isNeedPushHistory = true, anchor = '') {
    const nextPageLink = findElem('.pagination-next'),
        loadMoreBtn = findElem('.load-more-btn'),
        contentBlock = findElem(contentBlockSelector),
        form = newFormData();

    if (!nextPageLink || !loadMoreBtn || !contentBlock) {
        return;
    } else if (!nextPageLink) {
        addSelector(loadMoreBtn, d_none_key);
        return;
    }

    const url = nextPageLink.href;

    loadMoreBtn.onclick = () => {
        form.set('load_more', 1);

        addSelector(loadMoreBtn, 'load');

        fetchFunc(url, form)
            .then(json => {
                if (json['html']) {
                    contentBlock.innerHTML = json['html'];

                    if (isNeedPushHistory) {
                        history.pushState(null, null, url);
                    }

                    loadMoreContent(contentBlockSelector, isNeedPushHistory, anchor);
                    scrollToTop(anchor);
                    addToCart();

                    setTimeout(lazyLoadImgs, 700);

                    runOtherFuncs();
                }
            });
    };
}

export { loadMoreContent };