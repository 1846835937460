import { arrayFrom, fetchFunc, findElems, findElem } from "./helpers.js";
import { saveCookie }           from "./cookie.js";
import { index_php_key }        from "./vars.js";

function showSomeContent() {
    const expires = window.infoContentExpires;

    const checkClickLink = () => {
        const telegramLink = findElem('.subscribe-link.telegram');

        if (!telegramLink) {
            return;
        }

        telegramLink.addEventListener('click', () => {
            saveCookie('is_telegram_subscribed', 1, (expires * 365));

            fetchFunc(index_php_key + 'extension/info_content/module/info_content.saveClickToTelegram&ctj=1', {}).then();
        });
    };

    const processClose = () => {
        const closeBtn = findElem('.info-content-html [data-bs-dismiss="offcanvas"]');

        if (closeBtn) {
            // const timestamp = new Date().getTime();
            closeBtn.addEventListener('click', () => saveCookie('is_show_info_content_today', 1, expires));
        }
    };

    arrayFrom(findElems('.info-content-html')).forEach(content => {
        const bsOffcanvas = new bootstrap.Offcanvas(content)

        bsOffcanvas.show();

        processClose();
    });

    checkClickLink();
}

export { showSomeContent };