function setDeviceType() {
    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        isTablet = /Tablet|iPad/i.test(navigator.userAgent),
        isDesktop = !isMobile && !isTablet;

    if (isMobile) {
        window.deviceType = 'mobile';
    } else if (isTablet) {
        window.deviceType = 'tablet';
    } else if (isDesktop) {
        window.deviceType = 'desktop';
    } else {
        window.deviceType = 'unknown';
    }
}

export { setDeviceType };