export const index_php_key = 'index.php?route=',
    ajaxUrlToCart = index_php_key + 'checkout/cart',
    checkout_confirm = 'checkout/confirm',
    product_review_key = 'product/review',
    is_oct_buy_one_click = 'is_oct_buy_one_click',
    product_id_key = 'product_id',
    quantity_key = 'quantity',
    total_products_key = 'total_products',
    success_key = 'success',
    html_key = 'html',
    error_key = 'error',
    d_none_key = 'd-none',
    redirect_key = 'redirect',
    spinner_border_key = 'spinner-border',
    active_key = 'active',
    screenWidth = screen.width,
    document_var = document,
    body_var = document.body,
    lazy_load_key = 'lazy-load';